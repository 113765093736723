import React from "react"
import Layout from "../../../components/sermonLayoutSurprise"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://player.vimeo.com/video/379794389">
    <SEO title="Refuge - Christmas in Canaan" />
  </Layout>
)

export default SermonPost
